<template>
  <div
    class="featured-product-details"
    :class="`${hasImgDarkBackground ? 'to-white' : ''}`"
    v-if="productData"
  >
    <span class="featured-product-details__product-category">
      {{ productData.category }}
    </span>
    <nuxt-link :to="localePath(`/${productData.url}`)">
      <span class="featured-product-details__product-name">
        {{ productData.name }}
        <component
          :is="`${hasImgDarkBackground ? 'IconArrowWhite' : 'IconArrowBlack'}`"
          :class="`go-arrow-${isDesktop ? 'desktop' : 'mobile'}`"
        />
      </span>
    </nuxt-link>
    <span
      v-if="!isFarEastMarket && !isOnDemand"
      class="featured-product-details__product-price"
    >
      <span
        v-if="
          productData.specialPrice &&
          productData.specialPrice != productData.oldPrice
        "
      >
        ✺ {{ $t('from ') }}{{ $fc(productData.specialPrice) }}
      </span>
      <span v-else> ✺ {{ $fc(productData.oldPrice) }} </span>
      <span
        class="featured-product-old-price"
        v-if="
          productData.specialPrice &&
          productData.specialPrice != productData.oldPrice
        "
      >
        {{ $fc(productData.oldPrice) }}
      </span>
    </span>
    <span class="featured-product-details__product-available-in">
      {{ $t('Available in') }} {{ productData.availability }} {{ $t('colors') }}
    </span>
    <span class="featured-product-details__product-collection-name">
      {{ productData.collection }}
    </span>
  </div>
</template>

<script>
import {
  defineComponent,
  useContext,
  useFetch,
  ref,
} from '@nuxtjs/composition-api';
import { IconArrowBlack, IconArrowWhite } from '~/components/General/Icons';
import { useProduct, productGetters } from '@gemini-vsf/composables';
import { useMarkets } from '~/composables';

export default defineComponent({
  name: 'FeaturedProductDetails',
  components: {
    IconArrowBlack,
    IconArrowWhite,
  },
  props: {
    hasImgDarkBackground: {
      type: Boolean,
      default: false,
    },
    productSku: {
      type: String,
      default: '',
    },
    featuredProductLabel: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { app } = useContext();
    const { isFarEastMarket } = useMarkets();
    const { isDesktop } = app.$device;
    const { search, products: searchResult } = useProduct('featuredProduct');
    const featuredProduct = ref();
    const productData = ref();
    const isOnDemand = ref(false);

    useFetch(async () => {
      const baseSearchQuery = {
        filter: {
          code: props.productSku,
        },
      };
      await search({
        queryType: 'DETAIL',
        ...baseSearchQuery,
        customQuery: {
          productDetail: 'productDetailCustom',
        },
      });

      featuredProduct.value = searchResult.value?.items[0] ?? [];
      isOnDemand.value =
        featuredProduct.value?.variants?.[0]?.product?.sdc_ondemand;
      const colorVariantsNumber =
        featuredProduct.value?.configurable_options?.find((option) => {
          return option?.attribute_code === 'sdc_colore_vetro';
        })?.values?.length || 1;
      productData.value = {
        category:
          featuredProduct.value?.sdc_type_attribute?.label ?? 'Pendant lamps',
        name: productGetters.getName(featuredProduct.value),
        specialPrice: productGetters.getPrice(featuredProduct.value).special,
        oldPrice: productGetters.getPrice(featuredProduct.value).regular,
        availability: colorVariantsNumber,
        collection: props.featuredProductLabel,
        url: featuredProduct.value.url_key,
      };
    });

    return {
      isDesktop,
      productData,
      isFarEastMarket,
      isOnDemand,
    };
  },
});
</script>

<style lang="scss" scoped>
.featured-product-details {
  display: flex;
  flex-direction: column;
  position: relative;
  @include for-mobile {
    padding: 1.25rem 0 1.875rem 1.25rem;
  }
  &.to-white {
    color: var(--c-white);
    @include for-mobile {
      background-color: var(--c-black);
    }
  }
  &__product-name {
    @include mobile-h6;
    padding-bottom: 0.375rem;
    color: var(--c-black);
    @include for-desktop {
      @include desktop-h6;
    }
    .go-arrow {
      &-desktop {
        margin-bottom: -0.375rem;
        margin-left: -0.375rem;
        &:hover {
          cursor: pointer;
        }
      }
      &-mobile {
        position: absolute;
        bottom: 1.8rem;
        right: 1.8rem;
        transform: scale(2);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &__product-category {
    @include paragraph-s;
    padding-bottom: 0.3125rem;
  }
  &__product-price {
    padding-bottom: 0.375rem;
    @include paragraph-l;
    .featured-product-old-price {
      text-decoration: line-through;
      color: var(--c-dark-gray);
    }
  }
  &__product-available-in {
    @include paragraph-m;
    padding-bottom: 0.3125rem;
    @include for-desktop {
      padding-bottom: 1.625rem;
    }
  }
  &__product-collection-name {
    @include label-s;
  }
  @include for-desktop {
    margin-top: -5rem;
    &__product-name {
      padding-bottom: 0.6875rem;
    }
    &__product-price {
      padding-bottom: 0.6875rem;
    }
  }
}
</style>
