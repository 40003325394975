























import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import FeaturedProductDetails from './Featured/FeaturedProductDetails.vue';

export default defineComponent({
  name: 'FeaturedProduct',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { app } = useContext();
    const { isDesktop } = app.$device;
    const featuredProductData = ref(props.data);

    const featuredProductImgDesktop =
      featuredProductData.value.img.desktop ?? '/featuredProductDesktop.svg';
    const featuredProductImgMobile =
      featuredProductData.value.img.mobile ?? '/featuredProductMobile.svg';
    const featuredProductImgAlt =
      featuredProductData.value.img.alt ?? 'featured-product';
    const hasImgDarkBackground = featuredProductData.value.theme === 'Dark';
    const productSkuToDisplay = featuredProductData.value['product-sku'];
    const featuredProductLabel = featuredProductData.value.label ?? '';
    return {
      hasImgDarkBackground,
      featuredProductImgDesktop,
      featuredProductImgMobile,
      featuredProductImgAlt,
      productSkuToDisplay,
      isDesktop,
      featuredProductLabel,
    };
  },
  components: { FeaturedProductDetails },
});
